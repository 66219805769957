import Footer from "@/components/Footer";
import Header from "@/components/Header";
import Menu from "@/components/Menu";
import parse from "html-react-parser";
import Head from "next/head";
import { UseMeasureRef } from "react-use/lib/useMeasure";

function PageContainer({
	children,
	mainElementClasses,
	mainRef,
	script,
}: {
	children: React.ReactNode;
	mainElementClasses?: string;
	mainRef?: UseMeasureRef<HTMLElement>;
	script?: string | null;
}) {
	const parsedHtml = script ? parse(script) : null;

	return (
		<>
			<Head>{parsedHtml}</Head>
			<Menu />
			<div className="flex flex-col min-h-[calc(100vh-80px)] lg:min-h-screen">
				<Header />
				<main
					ref={mainRef}
					className={`mt-12 lg:mt-[7.5rem] pb-20 overflow-x-hidden relative h-full min-h-[30rem] ${mainElementClasses}`}
				>
					{children}
				</main>
				<Footer />
			</div>
		</>
	);
}

export default PageContainer;
