import Image from "next/image";
import Link from "next/link";
import ArrowLink from "./ArrowLink";

type Props = {
	imageSrc: string;
	imageAlt: string;
	title: string;
	subtitle?: string;
	linkHref: string;
	linkText: string;
	tags?: {
		name: string;
		slug?: string;
	}[];
	teamCard?: boolean;
};

const ListingCard = ({ imageSrc, imageAlt, title, subtitle, linkHref, linkText, tags, teamCard = false }: Props) => {
	return (
		<div className="grid h-full overflow-hidden group">
			<div className="pl-3 mr-3 lg:mr-0 lg:pl-[1.875rem] flex flex-col lg:gap-6 border-l-3 border-navy">
				{imageSrc && (
					<Link href={linkHref} className="overflow-hidden">
						<Image
							src={imageSrc}
							alt={imageAlt}
							width={800}
							height={800}
							className="duration-500 group-hover:scale-105"
						/>
					</Link>
				)}
				{tags && (
					<div className={`flex flex-wrap gap-2 lg:gap-[0.625rem] to-lg:mt-5 ${teamCard ? "lg:mt-1 lg:-mb-4" : ""}`}>
						{tags.map((tag, i) => ({
							...(tag.slug ? (
								<Link key={i} className="tag-pill" href={tag.slug}>
									{tag.name}
								</Link>
							) : (
								<div key={i} className="tag-pill">
									{tag.name}
								</div>
							)),
						}))}
					</div>
				)}
				<h3 className={`text-lg lg:text-xl to-lg:mt-1 ${teamCard ? "leading-none" : "leading-tight"}`}>{title}</h3>
				{subtitle && <p className={`text-base ${teamCard ? "lg:-mt-4" : ""}`}>{subtitle}</p>}
				{linkHref && linkText && (
					<ArrowLink className={`mt-auto to-lg:pt-9 ${teamCard ? "mt-6" : ""}`} href={linkHref} text={linkText} />
				)}
			</div>
		</div>
	);
};

export default ListingCard;
