import Head from "next/head";
import React from "react";

export const Helmet: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const author = "Neon Jungle";
	return (
		<Head>
			{/* Recommended Meta Tags */}
			<meta charSet="utf-8" />
			<meta name="language" content="english" />
			<meta httpEquiv="content-type" content="text/html" />
			<meta name="author" content={author} />
			<meta name="designer" content={author} />
			<meta name="publisher" content={author} />
			{children}

			{/* Search Engine Optimization Meta Tags */}
			<meta name="robots" content="index,follow" />
			<meta name="distribution" content="web" />

			{/* Meta Tags for HTML pages on Mobile */}
			<meta name="viewport" content="width=device-width, minimum-scale=1, initial-scale=1.0" />
			<meta name="theme-color" content="#000" />
		</Head>
	);
};

export function renderMeta(
	imageUrl: string = "",
	title: string = "",
	suffix: string = "",
	description: string = "",
	siteName: string = "",
	url: string = ""
) {
	// This is dumb but can't nest these in a fragment otherwise title gets wiped
	return [
		<title key="title">{title + suffix}</title>,
		<meta key="description" name="description" content={description} />,
		<meta key="og-title" property="og:title" content={title + suffix} />,
		<meta key="og-type" property="og:type" content="site" />,
		<meta key="og-locale" property="og:locale" content="en" />,
		<meta key="og-url" property="og:url" content={url} />,
		<meta key="og-image" property="og:image" content={imageUrl} />,
		<meta key="og-site" property="og:site_name" content={siteName} />,
		<meta key="og-desc" property="og:description" content={description} />,
		<meta key="twitter-title" name="twitter:title" content={title + suffix} />,
		<meta key="twitter-desc" name="twitter:description" content={description} />,
		<meta key="twitter-image" name="twitter:image" content={imageUrl} />,
		<meta key="twitter-card" name="twitter:card" content="summary" />,
		<meta key="twitter-domain" property="twitter:domain" content={url} />,
		<meta key="twitter-url" property="twitter:url" content={url} />,
	];
}
