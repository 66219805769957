import Image from "next/image";

const HeroImage = ({
	src,
	alt,
	isHomePage = false,
	caption,
}: {
	src: string;
	alt: string;
	isHomePage?: boolean;
	caption?: string | null;
}) => {
	if (!src) return null;
	return (
		<div className="relative -z-10">
			<Image
				className={`container ${isHomePage ? "pt-16 lg:pt-[4.25rem]" : ""}`}
				src={src}
				alt={alt}
				width={1400}
				height={840}
				priority
			/>
			{caption && (
				<div className="container mt-4 text-sm">
					<div className="pl-2 border-l border-l-pink">{caption}</div>
				</div>
			)}
		</div>
	);
};

export default HeroImage;
