import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { ExternalLinkBlock, InternalLinkBlock, SubMenuBlock, useGetMenuQuery } from "graphql/generated-types";
import Link from "next/link";
import SubArrow from "./svgs/sub-arrow.svg";
import { removeProtocolandRootPage } from "@/lib/utils";
import { useRouter } from "next/router";
import { Curve } from "./Curve";
import Logo from "./svgs/logo.svg";
import Shield from "./svgs/shield.svg";

const Menu = () => {
	const [isOpen, setIsOpen] = useState(false);
	const router = useRouter();
	const { data } = useGetMenuQuery();
	const subMenuBlocks = data?.menuSettings?.menu?.filter(
		(item) => item?.__typename === "SubMenuBlock"
	) as SubMenuBlock[];
	const otherLinks = data?.menuSettings?.menu?.filter((item) => item?.__typename !== "SubMenuBlock") as (
		| InternalLinkBlock
		| ExternalLinkBlock
	)[];

	useEffect(() => {
		setIsOpen(false);
	}, [router]);

	useEffect(() => {
		// Safari complete fuckery. useBodyLockScroll makes the menu unscrollable so we gotta do this.
		if (isOpen) {
			const scrollY = window.scrollY;

			// Set the body to a fixed position, along with other properties
			document.body.style.position = "fixed";
			document.body.style.top = `-${scrollY}px`;
			document.body.style.width = "100%";
		} else {
			// When the menu is not open, reset the body's position
			document.body.style.position = "";
			document.body.style.top = "";
			document.body.style.width = "";

			// Restore the scroll position of the page
			window.scrollTo(0, parseInt(document.body.style.top || "0") * -1);
		}

		// Clean up by resetting the body's position when the component unmounts
		return () => {
			document.body.style.position = "";
			document.body.style.top = "";
			document.body.style.width = "";
		};
	}, [isOpen]);

	return (
		<>
			<motion.nav
				initial={false}
				animate={isOpen ? "open" : "closed"}
				variants={{
					open: {
						x: 0,
						transition: {
							duration: 0.7,
							staggerChildren: 0.2,
							delayChildren: 0.7,
						},
					},
					closed: { x: "-101%" },
				}}
				transition={{ duration: 0.7, type: "easeIn" }}
				className="fixed top-20 lg:top-0  z-10 w-screen lg:w-[calc(100vw-77px)] min-h-[calc(100vh-80px)] lg:h-screen h-[calc(86vh-80px)] overflow-y-auto overflow-x-hidden lg:left-20 bg-beige lg:border-r-3 border-navy to-lg:outline outline-navy webkit-overflow-scrolling-touch">
				<div className="relative to-lg:pb-[15vh] lg:px-[8vw] 2xl:px-[15vw] lg:h-full overflow-y-auto overflow-x-hidden pt-12 menu-padding-top webkit-overflow-scrolling-touch">
					<div className="flex flex-col grid-cols-2 grid-rows-2 gap-8 to-lg:container lg:grid lg:gap-x-14 lg:gap-y-12">
						{subMenuBlocks?.map((item, i) => {
							return <SubMenuBuilder subMenuBlock={item} key={i} />;
						})}
						<div>
							{otherLinks?.map((item, i) => (
								<MenuLink href={item.href} text={item.text} key={i} />
							))}
						</div>
					</div>
					<RotatingCurve className="absolute bottom-0 right-0 -z-10" from={-90} to={-180} watch={isOpen} delay={0.8} />
				</div>
			</motion.nav>
			<aside className="lg:w-20 z-10 h-20 lg:h-full border-b-3 lg:border-b-0 lg:border-r-3 border-navy bg-beige lg:py-[3.75rem] relative">
				<div className="flex items-center justify-between h-full to-lg:container lg:justify-start lg:items-start">
					<Link href="/" className="lg:hidden">
						<span className="sr-only">Home</span>
						<Logo className="w-[5.625rem]" />
					</Link>
					<button
						className="z-20 lg:translate-y-1/2 lg:fixed lg:-rotate-90 lg:left-2 link-underline"
						onClick={() => setIsOpen((isOpen) => !isOpen)}>
						{isOpen ? "Close" : "Menu"}
					</button>
				</div>
				<Link href="/" className="to-lg:hidden fixed bottom-[3.75rem] left-3.5">
					<span className="sr-only">Home</span>
					<Shield className="w-12" />
				</Link>
			</aside>
		</>
	);
};

const SubMenuBuilder = ({ subMenuBlock }: { subMenuBlock: SubMenuBlock }) => {
	const subMenuItems = subMenuBlock.items as InternalLinkBlock[] | ExternalLinkBlock[];

	return (
		<motion.div
			variants={{
				open: {
					opacity: 1,
					transition: {
						opacity: { stiffness: 1000, velocity: -100 },
					},
				},
			}}>
			<MenuLink href={subMenuBlock.href} text={subMenuBlock.text} />
			{subMenuItems!.map((item, i) => (
				<MenuLink
					href={item.__typename === "InternalLinkBlock" ? removeProtocolandRootPage(item.href) : item.href}
					text={item.text}
					key={i}
					arrow
				/>
			))}
		</motion.div>
	);
};

const MenuLink = ({ href, text, arrow = false }: { href: string; text: string; arrow?: boolean }) => {
	return (
		<motion.div
			variants={{
				open: {
					opacity: 1,
					transition: {
						opacity: { stiffness: 1000, velocity: -100 },
					},
				},
				closed: {
					opacity: 0,
					transition: {
						opacity: { stiffness: 1000 },
					},
				},
			}}
			className="w-max">
			{arrow ? (
				<Link
					href={href}
					className="group flex gap-2 text-[1.375rem] to-lg:leading-normal lg:text-xl xl:text-2xl 2xl:text-3xl lg:gap-4 xl:gap-6">
					<SubArrow className="w-3 duration-300 lg:w-4 xl:w-6 group-hover:translate-x-1 group-hover:lg:translate-x-2 group-hover:xl:translate-x-3 stroke-[3px]" />
					{text}
				</Link>
			) : (
				<Link
					href={href}
					className="text-[1.375rem] to-lg:leading-normal lg:text-xl xl:text-2xl 2xl:text-3xl link-underline">
					{text}
				</Link>
			)}
		</motion.div>
	);
};

const RotatingCurve = ({
	className,
	watch,
	from,
	to,
	delay,
}: {
	className: string;
	watch: boolean;
	from: number;
	to: number;
	delay?: number;
}) => {
	return (
		<motion.div
			variants={{
				closed: {
					rotate: from,
					opacity: 0,
					transition: {
						duration: 0,
					},
				},
				open: {
					rotate: to,
					opacity: 1,
				},
			}}
			transition={{ duration: 0.5, type: "easeIn", delay: delay }}
			initial={false}
			animate={watch ? "open" : "closed"}
			className={className}>
			<Curve />
		</motion.div>
	);
};

export default Menu;
