import { InternalLinkBlock, useGetHeaderQuery } from "graphql/generated-types";
import Link from "next/link";
import Logo from "./svgs/logo.svg";

const Header = () => {
	const { data } = useGetHeaderQuery();
	const links = data?.menuSettings!.subrandLinks! as InternalLinkBlock[];

	return (
		<header className="hidden container mt-[3.75rem] lg:grid grid-cols-3">
			<Link href="/">
				<span className="sr-only">Home</span>
				<Logo className="w-[13.875rem]" />
			</Link>
			<div className="flex justify-between col-start-3 gap-6">
				{links?.map((link, i) => (
					<Link href={link?.href!} key={i} className="link-underline">
						{link?.text}
					</Link>
				))}
			</div>
		</header>
	);
};

export default Header;
