// probably shouldn't hardcode rootpage name?
export const removeRootPage = (path?: string) => {
	if (!path) return "";
	return path.replace(/^\/home+/, "");
};

export const removeProtocolandRootPage = (path: string) => path.replace(/^https?:\/\/[^/]+/, "");

export const pushQueryParam = (paths: string | undefined, router: any) => {
	const urlPaths = router.query.urlPaths!;
	let pathArray = urlPaths instanceof Array ? urlPaths : [urlPaths];
	paths && pathArray.push(paths);
	const newPath = pathArray.join("/");
	router.push(newPath, undefined, { shallow: true });
};
