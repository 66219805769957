import React from "react";
import Link from "next/link";
import Arrow from "./svgs/arrow.svg";

const ArrowLink = ({
  href,
  text,
  className,
}: {
  href?: string;
  text: string;
  className?: string;
}) => {
  if (!href) return null;
  return (
    <Link href={href} className={`flex items-center group gap-6 ${className}`}>
      <Arrow className="w-[2.375rem] fill-transparent text-pink group-hover:text-yellow duration-500 stroke-navy" />
      {text}
    </Link>
  );
};

export default ArrowLink;
