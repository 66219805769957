import React, { useRef } from "react";
import CurveSvg from "./svgs/curve.svg";
import { motion, useInView } from "framer-motion";

export const Curve = ({ className, from, to }: { className?: string; from?: number; to?: number }) => {
	const ref = useRef(null);
	const isInView = useInView(ref, { once: true });
	return (
		<motion.div
			ref={ref}
			variants={{
				hidden: {
					rotate: from,
					opacity: 0,
					transition: {
						duration: 0,
					},
					translateY: -1, // This is so bottom-0 is actually on screen and triggers the animation. It's imperceptible.
				},
				visible: {
					rotate: to,
					opacity: 1,
					translateY: 0,
				},
			}}
			initial={false}
			animate={isInView ? "visible" : "hidden"}
			transition={{ duration: 0.5, type: "easeIn" }}
			className={className}>
			<CurveSvg className="absolute w-[18.25rem] lg:w-[40.625rem]" />
		</motion.div>
	);
};
