import { InternalLinkBlock, useGetFooterQuery } from "graphql/generated-types";
import Link from "next/link";
import React from "react";
import ArrowLink from "./ArrowLink";

const Footer = () => {
	const { data } = useGetFooterQuery();
	if (!data) return null;
	const { footerSettings } = data;
	const links = footerSettings?.links as InternalLinkBlock[];

	return (
		<footer className="mt-auto border-t-3 border-navy">
			<div className="flex lg:container lg:items-center to-lg:flex-col lg:justify-between lg:py-16">
				<div className="to-lg:py-4 to-lg:border-b-3 border-navy">
					<ArrowLink
						href={`mailto:${footerSettings?.ctaEmail!}`}
						text={footerSettings?.ctaText!}
						className="to-lg:container"
					/>
				</div>
				<div className="flex gap-8 lg:gap-[7.5rem] to-lg:container to-lg:py-8">
					{links?.map((link, i) => (
						<Link href={link?.href!} key={i} className="link-underline">
							{link?.text}
						</Link>
					))}
				</div>
			</div>
		</footer>
	);
};

export default Footer;
