import React from "react";

const PageHeading = ({
	title,
	subtitle,
	children,
}: {
	title: string;
	subtitle?: string | null;
	children?: React.ReactNode;
}) => (
	<>
		<h1 className="-ml-1 text-2xl leading-none whitespace-pre-wrap to-lg:w-4/5 lg:text-4xl lg:col-span-2">{title}</h1>
		{subtitle && (
			<div className="flex flex-col justify-between col-start-3 mt-16 lg:mt-0">
				<p className="lg:text-xl">{subtitle}</p>
				{children}
			</div>
		)}
	</>
);

export default PageHeading;
